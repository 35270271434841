/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Для редиректа
import { domain } from "./config";

const MobileForm = () => {
    const [username, setUsername] = useState("");
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("00:00");
    const [duration, setDuration] = useState("");
    const [alert, setAlert] = useState({ message: '', type: '' }); // Для уведомлений
    const [showAlert, setShowAlert] = useState(false); // Для управления отображением
    const [translations, setTranslations] = useState({}); // Для хранения переводов
    const [initData, setInitData] = useState(""); // Для хранения данных инициализации
    const [availableSeats, setAvailableSeats] = useState({}); // Добавляем новое состояние
    const [isLoading, setIsLoading] = useState(true); // Добавляем состояние загрузки
    const [userTimezoneOffset, setUserTimezoneOffset] = useState(0); // Добавляем смещение часового пояса
    const navigate = useNavigate(); // Для редиректа

    // Функция для получения переводов
    const fetchTranslations = async (language, initData) => {
        const keys = [
            "username_label",
            "date_label",
            "start_label",
            "duration_label",
            "submit_button",
            "username_placeholder",
            "duration_placeholder",
            "history_button",
            "profile_button",
            "buy_description",
            "error_future_time"
        ];
        try {
            const response = await fetch(`${domain}/get-translations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'authorization': initData,
                },
                body: JSON.stringify({ keys, language }), // Указываем язык
            });
            const result = await response.json();
            setTranslations(result);
        } catch (error) {
            console.error(error);
        }
    };

    const checkAvailableSeats = async (selectedDate) => {
        try {
            const response = await fetch(`${domain}/check-seats`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': initData
                },
                body: JSON.stringify({
                    date: selectedDate
                })
            });

            const result = await response.json();
            if (response.ok) {
                const seatsMap = {};
                result.availableSeats.forEach(item => {
                    seatsMap[item.hour] = item.seats;
                });
                return seatsMap;
            }
            console.error('Error response:', result);
            return {};
        } catch (error) {
            console.error('Error checking seats:', error);
            return {};
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formattedUsername = username.toLowerCase();
        const now = new Date();
        const selectedDateTime = new Date(`${date}T${startTime}:00`);

        if (isNaN(selectedDateTime.getTime()) || selectedDateTime <= now) {
            setAlert({ message: translations.error_future_time, type: "error" });
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
            return;
        }

        const requestData = {
            streamer_id: formattedUsername,
            start_date: selectedDateTime.toISOString(), // Отправляем полную дату со временем
            work_time: duration // продолжительность в минутах
        };

        try {
            const tgData = window.Telegram.WebApp;
            const initData = tgData.initData;

            const response = await fetch(`${domain}/new-order`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'authorization': initData
                },
                body: JSON.stringify(requestData),
            });

            const result = await response.json();

            if (response.ok) {
                setAlert({ message: result.message, type: "success" }); // Сообщение об успехе
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    navigate('/history');
                }, 3000);
            } else {
                setAlert({ message: result.detail || "Failed to create order", type: "error" });
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
            }

        } catch (error) {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        }
    };

    useEffect(() => {
        // Получаем смещение часового пояса пользователя в часах
        const offset = -(new Date().getTimezoneOffset()) / 60;
        setUserTimezoneOffset(offset);

        const initTg = async () => {
            if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
                const tgData = window.Telegram.WebApp;
                const initData = tgData.initData;

                try {
                    const response = await fetch(`${domain}/user`, {
                        method: "GET",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': initData,
                            'ngrok-skip-browser-warning': "asd"
                        }
                    });

                    if (!response.ok) {
                        tgData.close();
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();

                    setInitData(initData);
                    await fetchTranslations(data.user.language_code, initData);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }

                setDate(getTodayDate());
            } else {
                setTimeout(initTg, 500);
            }
        };

        initTg();
    }, []);

    useEffect(() => {
        if (date) {
            setIsLoading(true);
            const fetchSeats = async () => {
                const seats = await checkAvailableSeats(date);
                setAvailableSeats(seats);
                setIsLoading(false);
            };
            fetchSeats();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, initData]);
    
    const getTodayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const renderTimeOptions = () => {
        return Array.from({ length: 24 }, (_, i) => {
            let adjustedHour = (i - 3 + userTimezoneOffset + 24) % 24;
            const formattedHour = String(adjustedHour).padStart(2, '0');
            const available = availableSeats[i] || 0;
            const isFull = available === 0;
    
            const selectedDate = new Date(date);
            const isWeekend = selectedDate.getDay() === 6 || selectedDate.getDay() === 0;
    
            // Заблокированные часы в будние дни
            const blockedHours = [17, 18, 19, 20, 21, 22, 23].map(h => (h - 3 + userTimezoneOffset + 24) % 24);
            const isBlocked = !isWeekend && blockedHours.includes(adjustedHour);
    
            return (
                <option
                    key={i}
                    value={formattedHour + ":00"}
                    disabled={isFull || isBlocked}
                    style={{
                        backgroundColor: isFull || isBlocked ? 'red' : undefined
                    }}
                >
                    {formattedHour}:00 
                </option>
            );
        });
    };
    

    return (
        <div className="form-container">
            {isLoading ? (
                <div className="loading">Loading...</div>
            ) : (
                <>
                    <button className="create-order-btn" onClick={() => navigate('/history')}>{translations.history_button}</button>
                    <button className="create-order-btn" style={{ "background": "green", "margin-left": "90px" }} onClick={() => navigate('/profile')}>{translations.profile_button}</button>

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>{translations.username_label}</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder={translations.username_placeholder} // Updated placeholder
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>{translations.date_label}</label>
                            <input
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                min={getTodayDate()}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>{translations.start_label}</label>
                            <select
                                className="select-time-btn"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                required
                            >
                                {renderTimeOptions()}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>{translations.duration_label}</label>
                            <input
                                type="number"
                                value={duration}
                                onChange={(e) => setDuration(parseInt(e.target.value, 10))}
                                placeholder={translations.duration_placeholder}
                                required
                            />
                        </div>
                        <button type="submit" className="submit-btn">
                            {translations.submit_button}
                        </button>
                        <br></br>
                        <div dangerouslySetInnerHTML={{ __html: translations.buy_description }} />
                    </form>

                    {showAlert && (
                        <div className={`alert ${alert.type}`} dangerouslySetInnerHTML={{ __html: alert.message }} />
                    )}
                </>
            )}
        </div>
    );
};

export default MobileForm;