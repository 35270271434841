import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { domain } from './config';
import AmountInput from './components/AmountInput';
import TariffCard from './components/TariffCard';

const UserProfile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [translations, setTranslations] = useState({});
    const navigate = useNavigate();
    const widgetRef = useRef(null);
    const [amount, setAmount] = useState("");

    const shop_id = "JbQ5o1ZJsRRCjB7u";
    const api_key = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiTWpZNU5qTT0iLCJ0eXBlIjoicHJvamVjdCIsInYiOiI2MTQ2ZDlmM2EwYTdhZjg5NDM4NWYzYTdjYzllOTAzNzM3YmYzMzQxMmJhZDJhNDBhNjUwNjA0YjQ5Yzg5ZmE3IiwiZXhwIjo4ODEyOTQyMzg3MX0.5wqsjIpdQuOw2gRT3LlRBejU1HuOu7pNh6C98C99w7M";
    const currency = "USD";
    const now_time = new Date().valueOf();

    const fetchTranslations = async (language, initData) => {
        const keys = ["history_button", "deposit_label", "hour", "hours"];
        try {
            const response = await fetch(`${domain}/get-translations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": initData,
                },
                body: JSON.stringify({ keys, language }),
            });
            const result = await response.json();
            setTranslations(result);
        } catch (error) {
            console.error(error);
        }
    };

    const calculateTariff = (amount) => {
        if (amount >= 2000) {
            return { price: 8, duration: `1 hour = $8` };  // $2000 = $8/hour
        } else if (amount >= 500) {
            return { price: 9, duration: `1 hour = $9` };  // $500 = $9/hour
        } else {
            return { price: 10, duration: `1 hour = $10` }; // Default tariff
        }
    };

    const tariffs = [
        { price: `> $500`, duration: `$9 ${translations.hour} (+10%)` },
        { price: `> $2000`, duration: `$8 ${translations.hour} (+20%)` },
    ];

    useEffect(() => {
        const initTg = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                const tgData = window.Telegram.WebApp;
                const initData = tgData.initData;

                try {
                    const response = await fetch(`${domain}/user`, {
                        method: "GET",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "authorization": initData
                        }
                    });

                    if (!response.ok) {
                        tgData.close();
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();
                    setUser(data.user);
                    await fetchTranslations(data.user.language_code, initData);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            } else {
                setTimeout(initTg, 500);
            }
        };

        initTg();
    }, []);

    useEffect(() => {
        if (!loading && widgetRef.current) {
            const script = document.createElement("script");
            script.src = "https://api.cryptocloud.plus/static/pay_btn/js/app.js";
            script.async = true;
            widgetRef.current.appendChild(script);

            // Переопределение window.open для открытия ссылки внутри Telegram
            const originalWindowOpen = window.open;
            window.open = (url, target, features) => {
                if (url && url.includes("cryptocloud")) {
                    window.location.href = url;
                    return null;
                } else {
                    return originalWindowOpen(url, target, features);
                }
            };

            // Восстановление оригинального window.open при демонтировании компонента
            return () => {
                window.open = originalWindowOpen;
            };
        }
    }, [loading]);

    if (loading) {
        return <div className="loading">...</div>;
    }

    const tariff = calculateTariff(Number(amount));

    return (
        <div className="profile-container">
            <button className="create-order-btn" onClick={() => navigate('/history')}>
                {translations.history_button}
            </button>

            <h2>{translations.deposit_label}</h2>
            <AmountInput currency="USD" onChange={setAmount} />
            <br /><br />

            <link href="https://api.cryptocloud.plus/static/pay_btn/css/app.css" rel="stylesheet" />
            <div ref={widgetRef} className="widget-container">
                <vue-widget
                    shop_id={shop_id}
                    api_key={api_key}
                    background="#fff"
                    color="#000"
                    border_color="#000"
                    logo="color"
                    width="350px"
                    currency={currency}
                    amount={amount}
                    text_btn="Deposit in crypto"
                    order_id={`${now_time}_${user?.id}`}
                />
                <div className="tariff-list">
                    {/* Выводим блок с тарифом, соответствующим введенной сумме */}
                    <TariffCard key={0} price={`Your price $${tariff.price}`} duration={tariff.duration} style={{ backgroundColor: 'yellow', marginLeft: "80px" }} />
                    <br></br>
                    {/* Отображаем все тарифы для справки */}
                    {tariffs.map((tariff, index) => (
                        <TariffCard key={index + 1} price={tariff.price} duration={tariff.duration} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
